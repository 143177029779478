/* eslint-disable no-undef */

import {SessionProvider} from 'next-auth/react';
import '../styles/global.css';
import React, {useEffect} from 'react';

function App({Component, pageProps: {session, ...pageProps}}) {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('/sw.js').then(
          function () {
            //console.log(
            //  'musicfox.io Service Worker registration successful with scope: ',
            //  registration.scope
            //);
          },
          function (err) {
            console.error(
              'tearsheetads.com Service Worker registration failed: ',
              err
            );
          }
        );
      });
    }
  }, []);
  return (
    <SessionProvider session={session}>
      <Component {...pageProps} />
    </SessionProvider>
  );
}

export default App;
